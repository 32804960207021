<template>
    <v-card
      :to="routeInfo"
      :color="categoryColor"
      >
      <v-img
        v-if="data.thumbnailUrl"
        :src="data.thumbnailUrl"
        class="white--text align-end"
        gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
        height="200px"
      >
        <v-card-title v-text="data.title"></v-card-title>
      </v-img>
      <v-card-title v-else>{{data.title}}</v-card-title>

      <v-card-text>{{ data.summary | truncate(100) }}</v-card-text>
    </v-card>
</template>

<script>

export default {
  name: "ArticleCard",
  props: {
    item: Object
  },
  data: function() {
    return {
      data: null,
      score: null,
      imageCdnHost: process.env.VUE_APP_IMG_CDN_HOST
    };
  },
  created() {
  },
  mounted() {
  },
  methods: {
  },
  computed: {
    routeInfo: function() {
      if (this.data.type == 'blog') {
        return {name: 'blogPost', params: {id: this.data.slug}};
      }
      return {name: 'article', params: {id: this.data.slug}};
    },
    categoryColor: function() {
      if (!this.data || !this.data.categoryId) 
        return '';
      if (this.data.categoryId == 'vitamins')
        return 'green lighten-4';
      if (this.data.categoryId == 'minerals')
        return 'green lighten-3';
      return 'green lighten-2';
    }
  },
  watch: {
    item: {
      // the callback will be called immediately after the start of the observation
      immediate: true, 
      handler (val, oldVal) {
        //console.log('Prop changed: ', val, ' | was: ', oldVal)
        if (val.o) {
          // scored list
          this.data = val.o;
          this.score = val.d || val.s;
        }
        else {
          this.data = val;
        }
      }
    }
  }
};
</script>

<style scoped>
</style>