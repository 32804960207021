<template>
  <div v-if="items && items.length > 0">
    <h3 class="headline">{{title}}</h3>
    <v-row dense>
      <v-col
        v-for="item in items"
        :key="item.o ? item.o.id : item.id"
        cols="6"
        sm="4"
        md="4"
        lg="3"
      >
        <ArticleCard :item="item"></ArticleCard>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ArticleCard from "@/components/ArticleCard.vue";

export default {
  name: "ArticleGrid",
  components: {
    ArticleCard
  },
  props: {
    items: Array,
    title: String,
  },
  data() {
    return {
    };
  },
  mounted() {
    console.log('articles', this.items && this.items.length > 0);
  },
  methods: {
  },
  watch: {
  }
};
</script>
