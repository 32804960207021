<template>
    <v-card
      :to="{name: 'ingredient', params: {id: data.slug ? data.slug : data.id}}"
      >
      <v-img
        v-if="data.imageName"
        :src="imageCdnHost + '/' + data.imageName + '?h=200&amp;fit=crop'"
        class="white--text align-end"
        gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
        height="200px"
      >
        <v-card-title v-text="data.name"></v-card-title>
      </v-img>
      <v-card-title v-if="!data.imageName" v-text="data.name" style="height:200px;" class="mb-4"></v-card-title>
      <v-card-text v-if="data.valueText">
        <div class="text--primary">
          <div>
            <v-chip title="Value" color="primary" v-if="data.valueText">{{data.valueText}}</v-chip>
            <span v-if="data.portionSizeText"> (avg. portion is {{data.portionSizeText}} g)</span>
          </div>
        </div>
      </v-card-text>
      <slot v-bind:data="data"></slot>
    </v-card>
</template>

<script>

export default {
  name: "IngredientCard",
  props: {
    item: Object
  },
  data: function() {
    return {
      data: null,
      score: null,
      imageCdnHost: process.env.VUE_APP_IMG_CDN_HOST
    };
  },
  created() {
  },
  mounted() {
  },
  methods: {
  },
  watch: {
    item: {
      // the callback will be called immediately after the start of the observation
      immediate: true, 
      handler (val, oldVal) {
        //console.log('Prop changed: ', val, ' | was: ', oldVal)
        if (val.o) {
          // scored list
          this.data = val.o;
          this.score = val.d || val.s;
        }
        else {
          this.data = val;
        }
      }
    }
  }
};
</script>

<style scoped>
</style>