import services from "./index";
import httpStore from "@/store/http-store";
import qs from "querystring";

export default {
    get(id) {
        return services.http.get("/v1.0/food/" + id);
    },
    getPopular(category = null, limit = 10) {
        if (category){
            return services.http.get("/v1.0/food/popular/"+category+"?limit="+limit);
        }
        return services.http.get("/v1.0/food/popular?limit="+limit);
    },
    getCombinations(id) {
        return services.http.get("/v1.0/food/" + id + "/combinations");
    },
    getRecipes(id) {
        return services.http.get("/v1.0/food/" + id + "/recipes");
    },
    list(ids) {
        return services.http.post("/v1.0/food/list", ids);
    },
    search(name) {
        return services.http.get("/v1.0/food/search/" + name);
    },
    getCategories() {
        return services.http.get("/v1.0/food/categories");
    },
    getCategory(id) {
        return services.http.get("/v1.0/food/categories/" + id);
    },
    autocomplete(name) {
        httpStore.commit('IGNORE_LOADING');
        return services.http.get("/v1.0/food/autocomplete/" + name);
    }
}