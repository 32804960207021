<template>
  <div>
    <v-row dense>
      <v-col
        v-for="item in items"
        :key="item.o ? item.o.id : item.id"
        cols="6"
        sm="4"
        md="4"
        lg="3"
      >
        <IngredientCard :item="item"></IngredientCard>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import IngredientCard from "@/components/IngredientCard.vue";

export default {
  name: "IngredientGrid",
  components: {
    IngredientCard
  },
  props: {
    items: Array
  },
  data() {
    return {
    };
  },
  mounted() {
  },
  methods: {
  }
};
</script>
