import services from "./index";
import httpStore from "@/store/http-store";

export default {
    get(id) {
        return services.http.get("/v1.0/nutrients/" + id);
    },
    getRecipes(id) {
        return services.http.get("/v1.0/nutrients/" + id + '/recipes');
    },
    list(ids) {
        return services.http.post("/v1.0/nutrients/list", ids);
    },
    getCategories() {
        return services.http.get("/v1.0/nutrients/categories");
    },
    getCategory(id) {
        return services.http.get("/v1.0/nutrients/categories/" + id);
    },
    autocomplete(name) {
        httpStore.commit('IGNORE_LOADING');
        return services.http.get("/v1.0/nutrients/search/" + name);
    },
    search(name) {
        return services.http.get("/v1.0/nutrients/search/" + name);
    }
}