<template>
  <div>
    <v-breadcrumbs v-if="contentType == 'blog'" :items="breadcrumbItems"/>
    <v-card v-if="article">
      <v-img v-if="article.imageUrl"
        :src="article.imageUrl"
        class="white--text align-end"
        gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)" 
        height="400px"
      >
        <v-card-title class="display-2" v-text="article.title"></v-card-title>
      </v-img>
      <v-card-title v-else class="display-2" v-text="article.title"></v-card-title>
      <v-card-text>
        <p class="text-muted pb-0">Posted on {{article.created}}</p>

        <div v-html="article.description" class="markdown pb-4 dynamic-content black--text font-weight-bold"> </div>
        <div v-html="article.content" class="markdown pb-4 dynamic-content black--text"> </div>

        <v-row v-if="contentType == 'blog'">
          <v-col cols="12" sm="6" v-if="article.previous">
            <v-card :to="{name: 'blogPost', params: { id: article.previous }}">
              <v-img v-if="article.previous_img"
                :src="article.previous_img"
                class="white--text align-end"
                gradient="to bottom, rgba(0,0,0,.3), rgba(0,0,0,.8)" 
                height="200px"
              >
                <v-card-title class="headline">
                  <i class="fa fa-arrow-left mr-2"/> Previous: <br/>
                  {{article.previous_title}}
                </v-card-title>
              </v-img>
              <v-card-title v-else class="headline">
                <i class="fa fa-arrow-left mr-2" /> Previous: <br/>
                {{article.previous_title}}
              </v-card-title>
            </v-card>
          </v-col>
          <v-col cols="12" sm="6" v-if="article.next">
            <v-card :to="{name: 'blogPost', params: { id: article.next }}">
              <v-img v-if="article.next_img"
                :src="article.next_img"
                class="white--text align-end"
                gradient="to bottom, rgba(0,0,0,.3), rgba(0,0,0,.8)" 
                height="200px"
              >
                <v-card-title class="headline">
                  <i class="fa fa-arrow-right mr-2"/> Next: <br/>
                  {{article.next_title}}
                </v-card-title>
              </v-img>
              <v-card-title v-else class="headline">
                <i class="fa fa-arrow-right mr-2"/> Next: <br/>
                {{article.next_title}}
              </v-card-title>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider/>
      <div v-if="nutrients" class="mx-4 mt-4">
        <h3 class="headline">Related nutrients</h3>
        <NutrientGrid :items="nutrients" />
      </div>
      <div v-if="recipes" class="mx-4 mt-4">
        <h3 class="headline">Related recipes</h3>
        <RecipeGrid :recipes="recipes" />
      </div>
      <div v-if="ingredients" class="mx-4 mt-4">
        <h3 class="headline">Related ingredients</h3>
        <IngredientGrid :items="ingredients" />
      </div>
    </v-card>
  </div>
</template>

<script>

import NutrientGrid from "@/components/NutrientGrid.vue";
import IngredientGrid from "@/components/IngredientGrid.vue";
import RecipeGrid from "@/components/RecipeGrid.vue";
import RecipeCard from "@/components/RecipeCard.vue";
import ArticleGrid from "@/components/ArticleGrid.vue";
import recipeService from "@/services/recipeService";
import foodService from "@/services/foodService";
import nutrientService from "@/services/nutrientService";
import articleService from "@/services/articleService";
import { EventBus } from '@/plugins/eventbus.js';
import Vue from "vue";

export default {
  name: "Article",
  components: {
    NutrientGrid,
    IngredientGrid,
    RecipeGrid,
    ArticleGrid,
    RecipeCard,
  },
  props: {
  },
  data: function() {
    return {
      contentType: null,
      article: null,
      title: null,
      image: null,
      nutrients: null,
      recipes: null,
      ingredients: null,
    };
  },
  created() {
  },
  async mounted() {
    this.contentType = this.$route.meta.type;
    const prefix = this.contentType ? `${this.contentType}/` : '';

    this.article = await articleService.get(prefix + this.$route.params.id);

    if (this.article == null) {
      // todo: redirect to 404
    }
    this.$nextTick(() => {
      this.parseEmbeddedRecipes();
    });
    //this.$parent.titleOverride = this.article.title;
    //this.$parent.imageOverride = this.article.images[0];
    this.image = this.article.images && this.article.images.length > 0 ? this.article.images[0] : null;
    EventBus.$emit('page-header-change', { title: this.article.title, image: null/*this.article.images[0]*/} );

    if (this.article.nutrients && this.article.nutrients.length > 0) {
      nutrientService.list(this.article.nutrients).then(response => {
        this.nutrients = response.data.data;
      }).catch(() => {
        this.nutrients = null;
      });
    }
    if (this.article.recipes && this.article.recipes.length > 0) {
      recipeService.list(this.article.recipes).then(response => {
        this.recipes = response.data.data;
      }).catch(() => {
        this.recipes = null;
      });
    }
    if (this.article.ingredients && this.article.ingredients.length > 0) {
      foodService.list(this.article.ingredients).then(response => {
        this.ingredients = response.data.data;
      }).catch(() => {
        this.ingredients = null;
      });
    }
    //window.title = this.article.title;
  },
  methods: {
    parseEmbeddedRecipes() {
      var recipes = document.querySelectorAll('div[data-recipe-id]');

      recipes.forEach((elem) => {
        var id = elem.dataset.recipeId;
        console.log('Loading recipe...', id);
        recipeService.get(id).then(response => {
          var recipe = response.data;
          if (recipe) {
            var ComponentClass = Vue.extend(RecipeCard)
            var instance = new ComponentClass({
                propsData: { recipe: recipe },
                parent: this,
            })
            instance.$mount() // pass nothing
            elem.appendChild(instance.$el);
            elem.classList.add('mb-4');
          }
        });
      });
    },
  },
  watch: {
     '$route.params.id': function (id) {
      this.loadData()
    }
 },
  computed: {
    breadcrumbItems() {
      return !this.article ? [] : [
        { text: 'Blog', exact: true, to: { name: 'blog' }},
        { text: this.article.title, disabled: true },
      ];
    },
  },
};
</script>

<style scoped>
</style>